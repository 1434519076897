.header-main{
    background: rgba(0, 0, 0, 0.8);
}

@media screen and (max-width: 899px) {
    .header-main{
        padding-left: 20px;
    }
}

.hover {
    border-bottom-width: 2px;
    border-bottom-color:white;
    padding-bottom: 5px;
    cursor: pointer;


  }
  .pointer:hover{
    cursor: pointer;
  }

@media screen and (min-width: 900px) {
    .header-main{
        display: flex;
        flex-direction: row;
    }   
    .routes{
        display: flex;
        flex-direction: row;

    }

}

.fixed-header{
    position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;
}