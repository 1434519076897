@media screen and (max-width: 899px) {

    .main-footer {
        padding: 20px;
        background-color: black;
    }
    .grow-text {
        margin-right: 20px;
        margin-left: 20px;
    }
    .service-list {
        padding-top: 50px;
    }
    .all-right-reserved {
        margin-right: 20px;
        margin-left: 20px;
    }
}

@media screen and (min-width: 900px) {
    .main-footer {
        display: flex;
        background-color: black;
    }
    .grow-text {
        margin-right: 60px;
        margin-left: 60px;
    }
    .service-list {
        padding-right: 162px;
    }
    .all-right-reserved {
        margin-right: 160px;
        margin-left: 160px;
        display: flex;
    }
}