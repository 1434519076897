/** @format */

.button-hover:hover {
  background-color: black;
  cursor: pointer;
  color: white !important;
}
.button-hover{
  background-color: white;
  cursor: pointer;
  color: black !important;
}
.remove-paddings {
  justify-content: center;
  align-items: center;
  padding: 12px 24px 12px 24px;
  border: solid 1px black;
  background-color: white;
}

@media (min-width: 900px) and (max-width: 920px) {
  .remove-paddings {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px black;
    background-color: white;
  }
}